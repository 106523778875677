import cn from 'classnames';
import React from 'react';

import { Typography } from '@components/Typography';
import './Button.css';
import { ButtonProps, ButtonShape, ButtonVariant, IconPosition } from './Button.types';
import { formatVariantStateClassNames } from './Button.utils';

const Button: React.FC<ButtonProps> = ({
  buttonClassNames,
  labelClassNames,
  classNames,
  disabled,
  error,
  href,
  Icon,
  iconPosition = IconPosition.LEFT,
  label,
  onClick,
  shape = ButtonShape.SQUARE,
  variant = ButtonVariant.DEFAULT_OUTLINE,
  children,
  ...props
}) => {
  const isCircleShapedIconButton = !!Icon && shape === ButtonShape.CIRCLE;
  const isEnabled = !disabled && !error;
  const isIconOnly = !label && !!Icon;
  const isIconPositionedRight = !!Icon && iconPosition === IconPosition.RIGHT;

  return (
    <div className={cn(['group', classNames])}>
      <a
        className="inline-block w-full h-full"
        href={href}
        rel="noopener noreferrer"
        target="_blank">
        <button
          className={`${formatVariantStateClassNames(!!disabled, !!error, variant)} ${
            isIconPositionedRight && '!flex-row-reverse'
          } ${isIconOnly && 'btn-icon'} ${
            isCircleShapedIconButton && '!rounded-full'
          } ${buttonClassNames}`}
          data-testid="button"
          disabled={!isEnabled}
          onClick={onClick}
          type="button"
          {...props}>
          {Icon}
          {label && (
            <Typography variant="button" className={labelClassNames}>
              {label}
            </Typography>
          )}
          {children}
        </button>
      </a>
    </div>
  );
};

export default Button;
