import { useApiClient } from '@/hooks/useApiClient';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { Typography } from '@components/Typography';

const LinkDifference = () => {
  const { getAppConfigByKey } = useApiClient();
  const isBigScreen = useIsBigScreen();
  const LLDifferenceText = getAppConfigByKey('LLDifferenceText');

  return (
    <div>
      {LLDifferenceText && (
        <div className="flex flex-col sm:gap-1 md:gap-4 sm:py-[14px] sm:px-6 md:p-0 sm:bg-cement-100 md:bg-transparent">
          <Typography
            variant={isBigScreen ? 'h4' : 'body-2'}
            className={'sm:!font-medium sm:!leading-5 md:!font-light md:!leading-[2.5rem]'}>
            The Link Logistics Difference
          </Typography>
          <Typography variant={isBigScreen ? 'body-1' : 'body-4'}>
            {LLDifferenceText?.data?.value}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default LinkDifference;
