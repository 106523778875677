import { TemplateAttributeKey, TemplateTypes, TemplateValue } from '@/types';
import {
  DATA_DISCLAIMER,
  orderDevelopmentPrimaryInfoLargeScreen,
  orderDevelopmentPrimaryInfoMediumScreen,
  orderDevelopmentUnitSpecs,
  orderIndustrialParkPrimaryInfoLargeScreen,
  orderIndustrialParkPrimaryInfoMediumScreen,
  orderIndustrialParkUnitSpecs,
  PropertyType,
} from '@/utilities/constants';
import { sortBy } from '@/utilities/functions';
import AssetsCarousel from '@components/AssetsCarousel';
import ContactBroker from '@components/ContactBroker';
import { IconName } from '@components/Icon';
import PrimaryInfo from '@components/PrimaryInfo/PrimaryInfo';
import { Typography } from '@components/Typography';
import UnitSpecs from '@components/UnitSpecs/UnitSpecs';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import LinkDifference from './LinkDifference';
import { PropertyListingsPageInitialElements } from './ListingDetails.types';
import ListingDetailsMap from './ListingDetailsMap';
import MarketDescription from './MarketDescription';
import SitePlanImage from './SitePlanImage';
export interface ListingInfoElementsWrapperProps {
  listingChildren: TemplateValue[];
  propertyType: string;
  isRouteHistoryStackEmpty: boolean;
  isSaved: boolean;
  setShowShareModal: (arg0: boolean) => void;
  setShowRemoveFavoritesModal: (arg0: boolean) => void;
  handleAddingFavoriteListing: (arg0: any) => void;
  handleDownloadFlyer: () => void;
  disableDownloadFlyerButton: boolean;
  marketDescription: AttributeValueType;
  market: AttributeValueType;
  propertyId: string;
}
const ListingInfoElementsWrapper: React.FC<ListingInfoElementsWrapperProps> = ({
  listingChildren,
  propertyType,
  isRouteHistoryStackEmpty,
  isSaved,
  setShowShareModal,
  setShowRemoveFavoritesModal,
  handleAddingFavoriteListing,
  handleDownloadFlyer,
  disableDownloadFlyerButton,
  marketDescription,
  market,
  propertyId,
}) => {
  const [showAssetsCarouselModal, setShowAssetsCarouselModal] = useState(false);
  let sitePlanUrl: any;
  const orderDevelopmentPrimaryInfo = useMediaQuery({ query: '(min-width: 1024px)' })
    ? orderDevelopmentPrimaryInfoLargeScreen
    : orderDevelopmentPrimaryInfoMediumScreen;
  const orderIndustrialParkPrimaryInfo = useMediaQuery({ query: '(min-width: 1024px)' })
    ? orderIndustrialParkPrimaryInfoLargeScreen
    : orderIndustrialParkPrimaryInfoMediumScreen;
  const {
    propertySpecifications,
    sitePlanImage,
    unitSpecifications,
    propertyDescription,
    squareFootageRange,
    map,
    contactInformation,
  } = listingChildren.reduce(
    (listingInfo, templateInfo) => {
      switch (templateInfo.templateType) {
        case TemplateTypes.PropertySpecifications:
          listingInfo.propertySpecifications = (
            <div className="flex justify-center bg-cement-100">
              <PrimaryInfo
                propertyId={propertyId}
                attributes={
                  propertyType === PropertyType.DEVELOPMENT ||
                  propertyType === PropertyType.PRE_DEVELOPMENT
                    ? sortBy(templateInfo?.attributes, orderDevelopmentPrimaryInfo)
                    : sortBy(templateInfo?.attributes, orderIndustrialParkPrimaryInfo)
                }
                propertyType={propertyType}
                isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
                isSaved={isSaved}
                setShowShareModal={setShowShareModal}
                setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
                handleAddingFavoriteListing={handleAddingFavoriteListing}
                handleDownloadFlyer={handleDownloadFlyer}
                downloading={disableDownloadFlyerButton}
              />
            </div>
          );
          break;

        case TemplateTypes.UnitSpecifications:
          templateInfo.attributes = templateInfo.attributes.filter(
            (unitSpec) => unitSpec.key !== 'OUTDOOR_STORAGE' && unitSpec.key !== 'TENANCY',
          );
          templateInfo.attributes.forEach((unitSpec) => {
            if (
              unitSpec.key === 'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT' &&
              unitSpec.value != 'To-suit' &&
              unitSpec.value != '--'
            ) {
              unitSpec.value = Math.round(unitSpec.rawValue).toLocaleString() + ' SF';
            }
          });
          listingInfo.unitSpecifications = (
            <UnitSpecs
              attributes={
                propertyType === PropertyType.DEVELOPMENT ||
                propertyType === PropertyType.PRE_DEVELOPMENT
                  ? sortBy(templateInfo?.attributes, orderDevelopmentUnitSpecs)
                  : sortBy(templateInfo?.attributes, orderIndustrialParkUnitSpecs)
              }
              propertyType={propertyType}
              isMobileView={false}
            />
          );
          break;

        case TemplateTypes.SquareFootageRange:
          listingInfo.squareFootageRange = templateInfo.attributes[0].rawValue && (
            <div className="w-full">
              <Typography className="mb-6 font-medium" variant="h5">
                Unit Details
              </Typography>
              <div className="flex mb-8 space-x-32">
                <Typography variant="body-2">Available Size</Typography>
                <Typography variant="subtitle-2">{templateInfo.attributes[0].value}</Typography>
              </div>
            </div>
          );

          break;
        case TemplateTypes.SitePlan: {
          if (templateInfo.attributes[0].rawValue != null) {
            listingInfo.sitePlanImage = (
              <SitePlanImage sitePlanUrl={templateInfo.attributes[0].rawValue} />
            );
          }
          break;
        }
        case TemplateTypes.ListingDetailsMap: {
          const latitude = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LATITUDE',
          );
          const longitude = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LONGITUDE',
          );
          listingInfo.map = (
            <div className="flex w-full">
              <div className="flex flex-col w-full pt-4 pb-8">
                <Typography className="mb-6  !text-2xl !leading-[30px] !font-medium" variant="h5">
                  Map View
                </Typography>
                <div className="[&_.mapboxgl-map]:rounded-[0.4375rem] w-full h-[40rem] pointer-events-none drop-shadow-lg listing-map">
                  <ListingDetailsMap
                    latitude={latitude?.rawValue}
                    longitude={longitude?.rawValue}
                  />
                </div>
              </div>
            </div>
          );
          break;
        }
        case TemplateTypes.ContactInformation: {
          const brokerName = templateInfo.attributes.find(
            (attribute) => attribute.key === 'BROKER_NAME',
          );
          const brokerPhone = templateInfo.attributes.find(
            (attribute) => attribute.key === 'BROKER_PHONE_NUMBER',
          );
          const brokerShop = templateInfo.attributes.find(
            (attribute) => attribute.key === 'BROKER_SHOP',
          );
          const brokerEmail = templateInfo.attributes.find(
            (attribute) => attribute.key === 'BROKER_EMAIL',
          );
          const brokerLogo = templateInfo.attributes.find(
            (attribute) => attribute.key === 'BROKER_SHOP_LOGO',
          );
          const linkRepEmail = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_EMAIL',
          );
          const linkRepName = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_NAME',
          );
          const linkRepPhone = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_PHONE_NUMBER',
          );
          const linkRepPhoto = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_HEADSHOT',
          );
          let nameToShow = brokerName?.rawValue ?? '--',
            emailToShow = brokerEmail?.rawValue ?? '--',
            phoneToShow = brokerPhone?.rawValue ?? '--',
            shopToShow = brokerShop?.rawValue ?? '--',
            photoToShow = brokerLogo?.rawValue ?? '--',
            linkRepNameToShow = linkRepName?.rawValue ?? '--',
            linkRepEmailToShow = linkRepEmail?.rawValue ?? '--',
            linkRepPhoneToShow = linkRepPhone?.rawValue ?? '--',
            linkRepPhotoToShow = linkRepPhoto?.rawValue ?? '--';

          listingInfo.contactInformation = (
            <ContactBroker
              brokerName={nameToShow}
              brokerEmail={emailToShow}
              brokerPhone={phoneToShow}
              brokerShop={shopToShow}
              brokerLogo={photoToShow === '--' ? undefined : photoToShow}
              emailIcon={brokerEmail?.icon as IconName}
              phoneIcon={brokerPhone?.icon as IconName}
              linkRepName={linkRepNameToShow}
              linkRepEmail={linkRepEmailToShow}
              linkRepPhone={linkRepPhoneToShow}
              linkRepPhoto={linkRepPhotoToShow === '--' ? undefined : linkRepPhotoToShow}
              isBigScreen={true}
            />
          );
          break;
        }
      }

      return listingInfo;
    },
    {
      propertySpecifications: null,
      sitePlanImage: null,
      unitSpecifications: null,
      propertyDescription: null,
      squareFootageRange: null,
      map: null,
    } as PropertyListingsPageInitialElements,
  );
  return (
    <>
      {propertySpecifications}
      <div className="flex flex-col justify-center py-8">
        <div className="flex justify-center">
          <div
            id="two-column-body"
            className="flex flex-row item-start lg:max-w-[71.25rem] md:max-w-[45rem] px-15px">
            <div
              id="first-column"
              className="flex flex-col justify-start gap-12 lg:min-w-[65%] md:min-w-[60%] lg:max-w-[65%] md:max-w-[60%] w-full">
              {!!propertyDescription && <div>{propertyDescription}</div>}
              {!!squareFootageRange && <div>{squareFootageRange}</div>}
              {!!unitSpecifications && <div>{unitSpecifications}</div>}
              {!!sitePlanImage && <div>{sitePlanImage}</div>}
              {!!map && <div>{map}</div>}
            </div>
            <div className="flex-grow"></div>
            <div id="second-column" className="flex flex-col gap-12 w-[30%]">
              <div id="contactBannerBottom">
                {!!contactInformation && <div>{contactInformation}</div>}
              </div>
              <MarketDescription market={market} marketDescription={marketDescription} />
              <LinkDifference />
            </div>
          </div>
        </div>
        <div className="flex justify-center my-8">
          <Typography
            variant="body-3"
            className="lg:max-w-[71.25rem] md:max-w-[45rem] px-15px text-[#777777]">
            {DATA_DISCLAIMER}
          </Typography>
        </div>
      </div>
      {sitePlanUrl && showAssetsCarouselModal && (
        <AssetsCarousel assets={sitePlanUrl} onClose={() => setShowAssetsCarouselModal(false)} />
      )}
    </>
  );
};
export type AttributeValueType = string | Date | null;
export const getKeyValue = (
  listing: TemplateValue,
  key: TemplateAttributeKey,
): AttributeValueType => {
  const attribute = listing?.attributes.find((attr) => attr.key === key);
  if (attribute) {
    return attribute?.value;
  }
  if (listing?.children) {
    for (const child of listing.children) {
      const result = getKeyValue(child, key);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

export const encodeSubMarket = (SubMarket: string) => {
  return SubMarket?.replace(/\//g, '%252F');
};

export default ListingInfoElementsWrapper;
