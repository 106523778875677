import Button, { ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import Link from '@components/Link/Link';
import { Typography } from '@components/Typography';
import React, { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import ListingActions from './ListingActions';
import { AttributeValueType } from './ListingDetails.utils';

type ListingDetailsHeaderProps = {
  isRouteHistoryStackEmpty: boolean;
  isPublicApp: boolean;
  isBigScreen: boolean;
  propertyID: string;
  propertyName: AttributeValueType;
  downloading: boolean;
  isSaved: boolean;
  setShowShareModal: Dispatch<SetStateAction<boolean>>;
  setShowRemoveFavoritesModal: Dispatch<SetStateAction<boolean>>;
  handleAddingFavoriteListing: (id: string) => void;
  handleDownloadFlyer: () => Promise<void>;
  navigate: NavigateFunction;
};

const ListingDetailsHeader: React.FC<ListingDetailsHeaderProps> = ({
  isRouteHistoryStackEmpty,
  isPublicApp,
  isBigScreen,
  propertyID,
  propertyName,
  downloading,
  isSaved,
  setShowShareModal,
  setShowRemoveFavoritesModal,
  handleAddingFavoriteListing,
  handleDownloadFlyer,
  navigate,
}) => {
  return (
    <div
      id="property-listing-header"
      className="flex justify-center w-full h-14 md:h-[3.3rem] bg-cement-100">
      <div className="flex items-center lg:max-w-[71.25rem] md:max-w-[45rem] w-full sm:px-[15px] md:px-0">
        {/* Back Button only for Internal App and Mobile View for Public App*/}
        {(!isRouteHistoryStackEmpty && !isPublicApp) ||
        (!isRouteHistoryStackEmpty && isPublicApp && !isBigScreen) ? (
          <div className="float-left w-1/2">
            <Button
              Icon={<Icon name={IconName.CHEVRON_LEFT} />}
              label="Back"
              onClick={() => navigate(-1)}
              variant={ButtonVariant.NO_FILL}
              aria-label="Go back"
            />
          </div>
        ) : null}

        {/* Breadcrumb Navigation for Public App and Desktop View */}
        {isPublicApp && isBigScreen && (
          <div className="flex flex-row p-2.5">
            <Link url={'https://linklogistics.com/'}>
              <Typography
                variant="body-3"
                className="!font-light hover:text-slate-500 hover:!font-semibold cursor-pointer">
                Home
              </Typography>
            </Link>
            <Typography variant="body-3" className="!font-light mx-1">
              /
            </Typography>
            <div
              onClick={() => (isRouteHistoryStackEmpty ? navigate('/map') : navigate(-1))}
              className="cursor-pointer "
              role="none">
              <Typography
                variant="body-3"
                className="!font-light hover:text-slate-500 hover:!font-semibold">
                Search Available Properties
              </Typography>
            </div>
            <Typography variant="body-3" className="!font-light mx-1">
              /
            </Typography>
            <Typography variant="subtitle-3" className="!font-medium ">
              {(propertyName as string) ?? 'Listing Name'}
            </Typography>
          </div>
        )}
        {/* Listing Actions for Small Screens */}
        {!isBigScreen && (
          <ListingActions
            propertyID={propertyID}
            isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
            isSaved={isSaved}
            setShowShareModal={setShowShareModal}
            setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
            handleAddingFavoriteListing={handleAddingFavoriteListing}
            handleDownloadFlyer={handleDownloadFlyer}
            downloading={downloading}
          />
        )}
      </div>
    </div>
  );
};

export default ListingDetailsHeader;
