import { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import { TemplateAttributeKey, TemplateTypes } from '@/types';
import FooterPublic from '@components/FooterPublic/FooterPublic';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import RemoveSavedModal from '@components/RemoveSavedModal';
import { ShareListingModal } from '@components/ShareListingModal';

import useIsBigScreen from '@/hooks/useIsBigScreen';
import useIsPublicApp from '@/hooks/useIsPublicApp';
import Footer from '@components/Footer';
import ListingPageMetaTag, {
  ListingPageMetaTagProps,
} from '@components/MetaTags/ListingPageMetaTag';
import './ListingDetails.css';
import { encodeSubMarket, getKeyValue } from './ListingDetails.utils';
import ListingDetailsContent from './ListingDetailsContent-component';
import ListingDetailsHeader from './ListingDetailsHeader';
import MarketingBanner from './MarketingBanner';

export const ListingDetails = () => {
  const isBigScreen = useIsBigScreen();
  const [showRemoveFavoritesModal, setShowRemoveFavoritesModal] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<ListingPageMetaTagProps>();

  const { getListingData, getFlyer, updateListingViewCount } = useApiClient();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { addFavorite, removeFavorites, favorites, isFavorite } = useUserFavorites();
  const { addToast } = useToast();
  const { mutate: updateListingViewCountMutation } = updateListingViewCount();
  const isPublicApp = useIsPublicApp();

  const propertyID = params.id!;
  // Should return true if URL shared or open in new browser tab
  const isRouteHistoryStackEmpty = location.key === 'default';

  // TODO Need to handle error state
  const { data, isLoading, isFetching, isError } = getListingData(TemplateTypes.DetailedListing, [
    propertyID,
  ]);

  const propertyType = data ? data[0]?.propertyType : '';
  const isSaved = useMemo(() => isFavorite(propertyID), [favorites]);
  const propertyName = data ? getKeyValue(data[0], TemplateAttributeKey.Name) : '';
  const brokerFlyer = (
    data ? getKeyValue(data[0], TemplateAttributeKey.BrokerFlyer) : ''
  ) as string;

  if (data) {
    const isVisibleListing = getKeyValue(data[0], TemplateAttributeKey.IsVisible);
    const market = encodeSubMarket(getKeyValue(data[0], TemplateAttributeKey.Market) as string);
    const SubMarket = encodeSubMarket(
      getKeyValue(data[0], TemplateAttributeKey.Submarket) as string,
    );
    if (isVisibleListing === 'false') {
      navigate(`/market/${market}/${SubMarket}`);
    }
  }

  const handleAddingFavoriteListing = (id: string) => {
    addFavorite(id);
  };

  const handleRemovingFavoriteListing = (id: string) => {
    removeFavorites([id]);
    setShowRemoveFavoritesModal(false);
  };

  const handleDownloadFlyer = async () => {
    setDownloading(true);
    if (brokerFlyer && brokerFlyer != '' && brokerFlyer != '--') {
      const link = document.createElement('a');
      link.href = brokerFlyer;
      link.target = `_blank`;
      link.dispatchEvent(new MouseEvent('click'));
    } else {
      const data = await getFlyer(propertyID);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.download = `flyer-${propertyID}-${new Date().getTime()}.pdf`;
      link.dispatchEvent(new MouseEvent('click'));
    }
    addToast({
      id: `download-flyer-${new Date().getTime()}`,
      title: `Success`,
      description: 'Listing Flyer Downloaded',
      type: 'success',
    });
    setDownloading(false);
  };

  useEffect(() => {
    if (data) {
      const primaryAttributes = data[0]?.children[0]?.attributes;
      const specAttributes = data[0]?.children[2]?.attributes;
      let name = primaryAttributes?.filter((a) => a.key === 'NAME')[0]?.value;
      const address = primaryAttributes?.filter((a) => a.key === 'FULL_ADDRESS')[0]?.value;
      const market = primaryAttributes?.filter((a) => a.key === 'MARKET')[0]?.value;
      const size = specAttributes?.filter(
        (a) => a.key === 'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT',
      )[0]?.value;
      const unit = specAttributes?.filter((a) => a.key == 'UNIT_NAME')[0]?.value;
      name = name + (unit ? ` - Unit ${unit}` : '');

      setMetadata({
        name,
        address,
        location: market,
        size,
        seoJsonMarkup: data[0]?.seoJsonContext,
      });
    }
  }, [data]);

  useEffect(() => {
    updateListingViewCountMutation({
      listingId: propertyID,
      listingMetricType: 0,
    });
  }, []);

  if (isError || (!isLoading && data.length === 0)) {
    return <Navigate to="/not-found" />;
  }

  return (
    <>
      <ListingPageMetaTag
        name={metadata?.name}
        address={metadata?.address}
        location={metadata?.location}
        size={metadata?.size}
        seoJsonMarkup={metadata?.seoJsonMarkup}
      />
      <div
        id="property-listing-page"
        className="overflow-y-auto min-h-[calc(100vh-52px)] flex flex-col justify-between">
        {isFetching || isLoading ? (
          <div className="flex items-center justify-center w-full p-10">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <ListingDetailsHeader
              isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
              isPublicApp={isPublicApp}
              isBigScreen={isBigScreen}
              propertyID={propertyID}
              propertyName={propertyName}
              isSaved={isSaved}
              setShowShareModal={setShowShareModal}
              setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
              handleAddingFavoriteListing={handleAddingFavoriteListing}
              handleDownloadFlyer={handleDownloadFlyer}
              downloading={downloading}
              navigate={navigate}
            />
            <MarketingBanner isBigScreen={isBigScreen} />
            <ListingDetailsContent
              propertyId={propertyID}
              data={data}
              isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
              isSaved={isSaved}
              setShowShareModal={setShowShareModal}
              setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
              handleAddingFavoriteListing={handleAddingFavoriteListing}
              handleDownloadFlyer={handleDownloadFlyer}
              disableDownloadFlyerButton={downloading}
            />
            <RemoveSavedModal
              category="favorite"
              onCancel={() => setShowRemoveFavoritesModal(false)}
              onRemove={() => handleRemovingFavoriteListing(propertyID)}
              show={showRemoveFavoritesModal}
              subtext="Removing these listing(s) from Favorites will also remove them from any saved Collections."
            />
            <ShareListingModal
              listingId={propertyID}
              propertyType={propertyType}
              show={showShareModal}
              onClose={() => {
                setShowShareModal(false);
              }}
            />
          </div>
        )}
        {isPublicApp ? <FooterPublic /> : <Footer />}
      </div>
    </>
  );
};

export default ListingDetails;
