import Button, { ButtonShape, ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import cn from 'classnames';

import useDeviceType from '@/hooks/useDeviceType';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { useIsAuthenticated } from '@azure/msal-react';
import Spinner from '@components/LoadingSpinner/Spinner';
import { Typography } from '@components/Typography';
import { useEffect, useState } from 'react';
import './ListingDetails.css';

export interface ListingActionsProps {
  propertyID: any;
  isRouteHistoryStackEmpty: any;
  isSaved: any;
  setShowShareModal: (arg0: boolean) => void;
  setShowRemoveFavoritesModal: (arg0: boolean) => void;
  handleAddingFavoriteListing: (arg0: any) => void;
  handleDownloadFlyer: () => void;
  downloading: boolean;
}
const ListingActions: React.FC<ListingActionsProps> = ({
  propertyID,
  isRouteHistoryStackEmpty,
  isSaved,
  setShowShareModal,
  setShowRemoveFavoritesModal,
  handleAddingFavoriteListing,
  handleDownloadFlyer,
  downloading,
}) => {
  const deviceType = useDeviceType();
  const [favIcon, setFavIcon] = useState(isSaved ? IconName.STAR_FILLED : IconName.STAR);
  const [downloadIcon, setDownloadIcon] = useState(
    downloading ? <Spinner /> : <Icon name={IconName.DOWNLOAD} />,
  );
  const isAuthenticated = useIsAuthenticated();
  const isBigScreen = useIsBigScreen();
  const getLabel = () => {
    if (deviceType === 'click') {
      return isSaved ? 'Remove from Favorites' : 'Add to Favorites';
    }
    return '';
  };

  useEffect(() => {
    downloading ? setDownloadIcon(<Spinner />) : setDownloadIcon(<Icon name={IconName.DOWNLOAD} />);
  }, [downloading]);

  useEffect(() => {
    isSaved ? setFavIcon(IconName.STAR_FILLED) : setFavIcon(IconName.STAR);
  }, [isSaved]);
  return (
    <div
      className={cn([
        'flex sm:justify-end md:justify-start items-center space-x-2 md:space-x-4',
        isRouteHistoryStackEmpty || isBigScreen ? 'w-full' : 'w-1/2',
      ])}>
      <div
        role="none"
        id="download-flyer-section"
        className={cn([
          'flex flex-row justify-center items-center md:hover:opacity-70 sm:text-slate-500 md:text-white-100 md:rounded-[22px]',
          'h-10 sm:pl-3 sm:pr-0 sm:py-3 md:px-6 md:py-3 gap-4 cursor-pointer',
          downloading ? 'md:bg-cement-600 pointer-events-none' : 'md:bg-base-black',
        ])}
        onClick={() => handleDownloadFlyer()}>
        {downloadIcon}
        {isBigScreen && (
          <Typography
            variant="body-3"
            className={cn([
              'uppercase !font-semibold !leading-[18px] !tracking-[1px]',
              downloading ? 'disabled' : '',
            ])}>
            Download Flyer
          </Typography>
        )}
      </div>

      <Button
        shape={ButtonShape.CIRCLE}
        Icon={<Icon classNames="!w-[1.25rem] pl-auto pr-0 py-0" name={IconName.SHARE} />}
        label={deviceType === 'click' ? 'Share Listing' : undefined}
        onClick={() => setShowShareModal(true)}
        variant={ButtonVariant.REVEAL_ACTION}
        classNames="w-[2.5rem] h-[2.5rem] hover:w-auto"
        buttonClassNames="!py-[0.5rem] !pl-[0.75rem] !hover:pr-[0.75rem] !pr-[0.5rem] !h-auto items-center"
      />
      {isAuthenticated && (
        <Button
          shape={ButtonShape.CIRCLE}
          Icon={<Icon name={favIcon} />}
          label={getLabel()}
          classNames="w-[2.5rem] h-[2.5rem] hover:w-auto"
          buttonClassNames="!py-[0.5rem] !pl-[0.5rem] !hover:pr-[0.75rem] !pr-[0.5rem] !h-auto items-center"
          onClick={() => {
            if (isSaved) {
              setShowRemoveFavoritesModal(true);
            } else {
              handleAddingFavoriteListing(propertyID);
            }
          }}
          onMouseOver={() => {
            if (deviceType === 'click') {
              isSaved ? setFavIcon(IconName.STAR) : setFavIcon(IconName.STAR_FILLED);
            }
          }}
          onMouseOut={() => {
            if (deviceType === 'click') {
              isSaved ? setFavIcon(IconName.STAR_FILLED) : setFavIcon(IconName.STAR);
            }
          }}
          variant={ButtonVariant.REVEAL_ACTION}
        />
      )}
    </div>
  );
};

export default ListingActions;
