import useIsBigScreen from '@/hooks/useIsBigScreen';
import { TemplateAttributeKey, TemplateValue } from '@/types/templates';
import './ListingDetails.css';
import { getKeyValue } from './ListingDetails.utils';
import ListingDetailsContentDesktop from './ListingDetailsContent-view-desktop';
import ListingDetailsContentMobile from './ListingDetailsContent-view-mobile';

export interface ListingDetailsContentProps {
  data: TemplateValue[];
  isRouteHistoryStackEmpty: boolean;
  isSaved: boolean;
  setShowShareModal: (arg0: boolean) => void;
  setShowRemoveFavoritesModal: (arg0: boolean) => void;
  handleAddingFavoriteListing: (arg0: any) => void;
  handleDownloadFlyer: () => void;
  disableDownloadFlyerButton: boolean;
  propertyId: string;
}
const ListingDetailsContent: React.FC<ListingDetailsContentProps> = ({
  data,
  isRouteHistoryStackEmpty,
  isSaved,
  setShowShareModal,
  setShowRemoveFavoritesModal,
  handleAddingFavoriteListing,
  handleDownloadFlyer,
  disableDownloadFlyerButton,
  propertyId,
}) => {
  const isBigScreen = useIsBigScreen();
  const marketDescription = getKeyValue(data[0], TemplateAttributeKey.MarketDescription);
  const market = getKeyValue(data[0], TemplateAttributeKey.Market);
  return (
    <>
      {isBigScreen ? (
        <ListingDetailsContentDesktop
          data={data}
          isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
          isSaved={isSaved}
          setShowShareModal={setShowShareModal}
          setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
          handleAddingFavoriteListing={handleAddingFavoriteListing}
          handleDownloadFlyer={handleDownloadFlyer}
          disableDownloadFlyerButton={disableDownloadFlyerButton}
          marketDescription={marketDescription}
          market={market}
          propertyId={propertyId}
        />
      ) : (
        <ListingDetailsContentMobile
          data={data}
          isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
          isSaved={isSaved}
          setShowShareModal={setShowShareModal}
          setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
          handleAddingFavoriteListing={handleAddingFavoriteListing}
          handleDownloadFlyer={handleDownloadFlyer}
          disableDownloadFlyerButton={disableDownloadFlyerButton}
          marketDescription={marketDescription}
          market={market}
          propertyId={propertyId}
        />
      )}
    </>
  );
};
export default ListingDetailsContent;
