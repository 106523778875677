import useIsBigScreen from '@/hooks/useIsBigScreen';
import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';
import { useMemo } from 'react';
import { ContactBrokerProps } from './ContactBroker.types';
export const getUserInitials = (name: string) => {
  let userInitials = `${name.slice(0, 1).toUpperCase()}`;
  const nameArr = name.split(' ');
  if (nameArr.length > 1) {
    userInitials += `${nameArr[nameArr.length - 1].slice(0, 1).toUpperCase()}`;
  }

  return userInitials;
};
const ContactCard: React.FC<{
  name: string;
  email: string;
  phone: string;
  photo: string;
  emailIcon: string;
  phoneIcon: string;
}> = ({ name, email, phone, photo, emailIcon, phoneIcon }) => {
  const userIcon = useMemo(() => {
    if (photo) {
      return (
        <div className="w-16 h-16">
          <img src={photo} alt={name} className="rounded-full" data-testid="photo-img" />
        </div>
      );
    } else if (name) {
      const userInitials = getUserInitials(name);

      return (
        <div>
          {userInitials && userInitials !== '-' && userInitials !== '--' ? (
            <div className="flex items-center justify-center w-16 h-16 rounded-full bg-solar-100">
              <Typography
                variant="h6"
                className="font-lg text-sm !font-normal text-white-100"
                data-testid="user-initials">
                {userInitials}
              </Typography>
            </div>
          ) : (
            <Icon classNames="!h-16 !w-16" name={IconName.GREY_USER_ICON} />
          )}
        </div>
      );
    }
  }, [photo, name]);
  return (
    <div className="flex flex-row items-center gap-8">
      {userIcon}
      <div className="flex flex-col gap-2">
        <Typography variant="body-3" className="!font-medium !leading-5">
          {name}
        </Typography>
        <div className="flex items-center gap-2">
          <Icon classNames="!w-4 !h-4" name={phoneIcon as IconName} />
          <Typography variant="body-3" className="!font-normal !leading-5">
            {phone}
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <Icon classNames="!w-4 !h-4" name={emailIcon as IconName} />
          <Typography variant="body-3" className="!font-normal !leading-5 underline">
            {'Contact Now'}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const ContactBroker: React.FC<ContactBrokerProps> = ({
  brokerName,
  brokerEmail,
  brokerPhone,
  brokerLogo,
  linkRepName,
  linkRepEmail,
  linkRepPhone,
  linkRepPhoto,
  emailIcon,
  phoneIcon,
}) => {
  const isBigScreen = useIsBigScreen();
  return (
    <div className="flex flex-col items-start gap-12">
      {isBigScreen && (
        <div id="contact-broker-section">
          <Typography
            variant="body-1"
            className="rounded-[22px] h-10 bg-cement-600 uppercase px-6 py-1.5 !font-semibold !leading-[18px] !tracking-[1px] flex justify-center items-center md:text-[13px]">
            Request Information
          </Typography>
        </div>
      )}

      <div className="flex flex-col items-start gap-2">
        <Typography className="text-slate-600 !leading-[18px] tracking-[2px]" variant="subtitle-3">
          Broker
        </Typography>
        <ContactCard
          name={brokerName}
          email={brokerEmail}
          phone={brokerPhone}
          photo={brokerLogo}
          emailIcon={emailIcon}
          phoneIcon={phoneIcon}
        />
      </div>

      <div className="flex flex-col items-start gap-2">
        <Typography className="text-slate-600 !leading-[18px] tracking-[2px]" variant="subtitle-3">
          Link Logistics Leasing Contact
        </Typography>
        <ContactCard
          name={linkRepName}
          email={linkRepEmail}
          phone={linkRepPhone}
          photo={linkRepPhoto}
          emailIcon={emailIcon}
          phoneIcon={phoneIcon}
        />
      </div>
    </div>
  );
};

export default ContactBroker;
