import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useUser } from '@/hooks/useUser';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import ConfirmationModal from '@components/ConfirmationModal';
import { Checkbox, CheckboxState, RadioButton, TextInput } from '@components/Inputs';
import { Typography } from '@components/Typography';
import { CreateReportModalProps, ExportTypes } from './CreateReportModal.types';
import {
  EXCEL_REPORT_CHUNK_SIZE,
  LONG_POLLING_INTERVAL,
  MAX_CHARACTERS_ALLOWED,
  PropertyDataExcelKeyMap,
} from '@/utilities/constants';
import { useToast } from '@/hooks/useToast';
import { useMediaQuery } from 'react-responsive';
import { renameKeysDeep, ternaryOperation } from '@/utilities/functions';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import './CreateReportModal.css';
import { Tooltip } from 'react-tooltip';
import { REPORT_MAX_LISTINGS, VACANCY_REPORT_MAX_LISTINGS } from '@/config';
import { IconName } from '@components/Icon';
import Spinner from '@components/LoadingSpinner/Spinner';
import { ListingExcelRow } from '@/types/excel';
import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { ToastType } from '@components/Toaster/Toaster';
import { ButtonVariant } from '@components/Button';
import { useLongPolling } from '@/hooks/useLongPolling';
import { formatNumberWithCommas } from '@/utilities/textHelpers';
import { useIsAuthenticated } from '@azure/msal-react';

const CreateReportModal: React.FC<CreateReportModalProps> = ({
  listingGroups,
  fromCollections,
  onConfirm,
  onClose,
}) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 499px)' });
  const isMediumScreen = useMediaQuery({ query: '(min-width: 768px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const { email, firstName, lastName } = useUser();

  const [selectedOption, setSelectedOption] = useState<ExportTypes | null>(null);
  const [createCollection, setCreateCollection] = useState(CheckboxState.EMPTY);

  const [reportName, setReportName] = useState(
    listingGroups[0].name?.slice(0, MAX_CHARACTERS_ALLOWED) ?? '',
  );
  const [collectionName, setCollectionName] = useState('');
  const [reportNameError, setReportNameError] = useState('');
  const [genericModalError, setGenericModalError] = useState('');
  const [disablePrimaryBtn, setDisablePrimaryBtn] = useState(false);
  const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(false);
  const [maxCharactersRemaining, setMaxCharactersRemaining] = useState(
    MAX_CHARACTERS_ALLOWED - (reportName?.length ?? 0),
  );
  const [exporting, setExporting] = useState<boolean>(false);
  const [emails, setEmails] = useState<string[]>([]);

  const { shareAvailabilityReportViaEmail, getDataForExcel, getVacancyMailerReport } =
    useApiClient();
  const { addFavoriteCollection } = useUserFavoriteCollections();
  const { addToast } = useToast();
  const { longpollFileAndDownload } = useLongPolling();

  const { mutate: shareAvailabilityReportViaEmailMutation } = shareAvailabilityReportViaEmail();
  const emptySpacesError = 'Collection name cannot be empty spaces or blank';
  const isAuthenticated = useIsAuthenticated();

  const getExcelData = async (listingIds: string[]) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let allListingData: ListingExcelRow[] = [];
    for (let i = 0; i < listingIds.length; i += EXCEL_REPORT_CHUNK_SIZE) {
      const chunk = listingIds.slice(i, i + EXCEL_REPORT_CHUNK_SIZE);
      // Call API for chunk
      const excelChunk = await getDataForExcel(chunk);
      allListingData = allListingData.concat(excelChunk);
    }
    if (allListingData.length > 0) {
      // Remove Property ID, Date Available, and Expiration Date if user is not authenticated
      if (!isAuthenticated) {
        allListingData.forEach((l) => delete l.PropertyId);
        allListingData.forEach((l) => delete l.DateAvailable);
        allListingData.forEach((l) => delete l.ExpirationDate);
        // Rename Submarket header to 'Location' if user is not authenticated
        PropertyDataExcelKeyMap.Submarket = 'Location';
      }
      // Remove Outdoor Storage and Tenancy for both internal and external users
      allListingData.forEach((l) => delete l.OutdoorStorage);
      allListingData.forEach((l) => delete l.Tenancy);

      const _allListingData = allListingData.map((listingData) => {
        const updatedObject = renameKeysDeep(listingData, PropertyDataExcelKeyMap);
        return updatedObject;
      });
      // create excel stream and assign to data
      const ws = XLSX.utils.json_to_sheet(_allListingData);
      let index = 1;
      let cellNumber = '';
      let subIndex = 1;
      Object.keys(allListingData[0]).forEach((key) => {
        if (index > 26) {
          cellNumber = `A${String.fromCharCode(subIndex + 64)}1`;
          subIndex++;
        } else {
          cellNumber = `${String.fromCharCode(index + 64)}1`;
        }
        if (ws[cellNumber]) {
          // Customize the header row font
          ws[cellNumber].s = {
            font: {
              bold: true,
            },
          };
          index++;
        }
      });
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      return new Blob([excelBuffer], { type: fileType });
    }
  };

  const handleVacancyMailerReport = async (listingIds: string[]) => {
    const successToast = {
      id: `download-vacancy-report-${new Date().getTime()}`,
      description: `Once it's ready, your file will be automatically downloaded`,
      title: 'Generating Report',
      type: 'success',
      autoRemoveTime: 15000,
    } as ToastType;
    const failToast = {
      id: `download-excel-report-${new Date().getTime()}`,
      description: `Failed to generate national availability report`,
      title: 'Error',
      type: 'error',
    } as ToastType;

    try {
      const fileIdResponse = await getVacancyMailerReport(listingIds);

      if (fileIdResponse) {
        // Start long polling for the file
        longpollFileAndDownload({ fileId: fileIdResponse, intervalInMs: LONG_POLLING_INTERVAL });

        addToast(successToast);
      } else {
        addToast(failToast);
      }
    } catch (e) {
      addToast(failToast);
    }
  };

  const handleDownloadExcelReport = async (listingIds: string[]) => {
    const fileExtension = '.xlsx';
    const successToast = {
      id: `download-excel-report-${new Date().getTime()}`,
      description: `Excel Report Downloaded`,
      title: 'Success',
      type: 'success',
    } as ToastType;
    const failToast = {
      id: `download-excel-report-${new Date().getTime()}`,
      description: `Failed to generate excel report`,
      title: 'Error',
      type: 'error',
    } as ToastType;
    try {
      const data = await getExcelData(listingIds);
      if (data) {
        // Save file
        FileSaver.saveAs(data, `download-excel-report-${new Date().getTime()}${fileExtension}`);
        addToast(successToast);
      } else {
        addToast(failToast);
      }
    } catch (e) {
      addToast(failToast);
    }
  };

  const handleAvailabilityReport = () => {
    for (const { listingIds } of listingGroups) {
      shareAvailabilityReportViaEmailMutation({
        recipient: emails,
        listingIds,
        title: reportName,
        senderEmail: email ?? 'no-reply@linklogistics.com',
        senderFirstName: firstName!,
        senderLastName: lastName!,
      });
    }

    if (allListingIds.length > 10) {
      addToast({
        id: 'generate-report',
        description: `Once it's ready, your file will automatically be sent via email`,
        title: 'Generating Report',
        type: 'success',
      });
    } else {
      addToast({
        id: 'generate-report',
        description: `Availability report sent to ${emails.length} ${
          emails.length > 1 ? 'people' : 'person'
        }`,
        title: 'Success!',
        type: 'success',
      });
    }
  };

  const createNewCollection = async () => {
    if (collectionName && collectionName.trim().length <= 0) {
      setGenericModalError(emptySpacesError);
      return;
    }
    setExporting(true);
    if (createCollection === CheckboxState.CHECKED && listingGroups.length == 1) {
      try {
        // New collection can only be created with 1 listing group.
        // So its safe to take listing IDs from first group.
        await addFavoriteCollection(collectionName, listingGroups[0].listingIds);

        addToast({
          id: 'create-collection',
          description: `Collection created successfully`,
          title: 'Success',
          type: 'success',
        });
      } catch (error: any) {
        const { exceptionMessage } = error.response.data.responseException;
        setGenericModalError(exceptionMessage);
        setExporting(false);
        setDisablePrimaryBtn(true);
        return;
      }
    }
    setExporting(false);
    onConfirm();
  };

  const handleButtonClick = async () => {
    if (
      selectedOption === ExportTypes.AVAILABILITY_REPORT &&
      reportName &&
      reportName.trim().length <= 0
    ) {
      setReportNameError('Report Name cannot be empty spaces or blank');
      return;
    }

    setExporting(true);
    // Availability report
    if (selectedOption === ExportTypes.AVAILABILITY_REPORT) {
      handleAvailabilityReport();
    } else if (selectedOption === ExportTypes.VACANCY_REPORT) {
      // Download Vacancy Mailer report
      await handleVacancyMailerReport(allListingIds);
    } else if (selectedOption === ExportTypes.EXCEL_EXPORT) {
      // Download Excel report
      await handleDownloadExcelReport(allListingIds);
    }
    setExporting(false);

    if (createCollection === CheckboxState.CHECKED) {
      // Show create collection modal
      setShowCreateCollectionModal(true);
    } else {
      onConfirm();
    }
  };

  const handleReportNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setMaxCharactersRemaining(MAX_CHARACTERS_ALLOWED - inputValue.length);

    setReportName(inputValue);
    setCollectionName(inputValue);
    setReportNameError('');
  };

  const handleCollectionNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setMaxCharactersRemaining(MAX_CHARACTERS_ALLOWED - inputValue.length);
    setCollectionName(inputValue);
    setGenericModalError('');
    if (inputValue) {
      setDisablePrimaryBtn(false);
    }
    if (exporting) {
      setExporting(false);
    }
  };

  const onChangeEmails = (emails: string[]) => {
    setEmails(emails);
  };

  const getLabelInput = (email: string, index: number, removeEmail: (index: number) => void) => (
    <div data-tag key={index}>
      {email}
      <button
        onClick={() => removeEmail(index)}
        style={{ cursor: 'pointer', marginLeft: '6px', fontSize: 18 }}>
        ×
      </button>
    </div>
  );

  const getPrimaryButtonText = () => {
    switch (selectedOption) {
      case ExportTypes.AVAILABILITY_REPORT:
        return 'Send Via Email';
      case ExportTypes.VACANCY_REPORT:
        return 'Download National Availability Report';
      case ExportTypes.EXCEL_EXPORT:
        return 'Export As Excel File';
      default:
        return 'Send Via Email';
    }
  };

  const exportingSpinner = exporting ? <Spinner /> : <></>;

  const showReportNameInput = !listingGroups[0].name;
  const showCreateCollectionCheckbox = showReportNameInput && isAuthenticated;
  const maxReportListingsReached = listingGroups.some(
    (g) => g.listingIds.length > REPORT_MAX_LISTINGS,
  );
  const maxVacancyReportListingsReached = listingGroups.some(
    (g) => g.listingIds.length > VACANCY_REPORT_MAX_LISTINGS,
  );

  const allListingIds = listingGroups
    .flatMap((g) => g.listingIds)
    .filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    });

  useEffect(() => {
    // If Report max listings is reached but not vacancy limit then set radio button to VACANCY_REPORT
    if (isAuthenticated) {
      if (maxReportListingsReached && !maxVacancyReportListingsReached) {
        setSelectedOption(ExportTypes.VACANCY_REPORT);
      } else if (maxReportListingsReached && maxVacancyReportListingsReached) {
        setSelectedOption(ExportTypes.EXCEL_EXPORT);
      } else {
        setSelectedOption(ExportTypes.AVAILABILITY_REPORT);
      }
    } else {
      if (maxReportListingsReached) {
        setSelectedOption(ExportTypes.EXCEL_EXPORT);
      } else {
        setSelectedOption(ExportTypes.AVAILABILITY_REPORT);
      }
    }
  }, [maxReportListingsReached, maxVacancyReportListingsReached]);

  return (
    <>
      {showCreateCollectionModal ? (
        <ConfirmationModal
          classNames=""
          header="Create New Collection"
          description={`Save the selected (${formatNumberWithCommas(
            allListingIds.length,
          )}) listings to a collection.`}
          primaryBtnLabel="Create Collection"
          primaryBtnOnClick={async () => await createNewCollection()}
          primaryBtnDisabled={exporting || !collectionName || disablePrimaryBtn}
          primaryBtnIcon={exportingSpinner}
          secondaryBtnLabel={'Cancel'}
          secondaryBtnVariant={ButtonVariant.DEFAULT_OUTLINE}
          secondaryBtnOnClick={onClose}
          onClose={onClose}
          show
          haveCloseButton={true}>
          <div className="flex flex-col items-start w-full mt-10 mb-4 sm:mt-4 sm:mb-2">
            <Typography className="mb-4" variant="body-4">
              Enter Collection Name
            </Typography>
            <TextInput
              classNames="h-[4.75rem] mb-[0.125rem] w-full"
              error={!!genericModalError}
              errorMessage={genericModalError}
              onChange={handleCollectionNameOnChange}
              value={collectionName}
              maxLength={MAX_CHARACTERS_ALLOWED}
              required={true}
              maxCharactersRemaining={maxCharactersRemaining}
              showRemainingCharactersText={true}
            />
          </div>
        </ConfirmationModal>
      ) : (
        <ConfirmationModal
          header={'Create Report'}
          classNames="sm:!pb-6"
          onClose={onClose}
          primaryBtnDisabled={
            exporting ||
            (selectedOption === ExportTypes.AVAILABILITY_REPORT &&
              (!emails || emails.length === 0 || !reportName))
          }
          primaryBtnIcon={exportingSpinner}
          primaryBtnLabel={getPrimaryButtonText()}
          primaryBtnOnClick={handleButtonClick}
          show
          errorMessage={genericModalError}
          maxHeight={isSmallScreen ? 'max-h-[80%]' : ''}
          subText={ternaryOperation(
            listingGroups.length === 1,
            `You are creating a new report from ${ternaryOperation(
              listingGroups[0].name,
              'this collection',
              `(${formatNumberWithCommas(
                listingGroups[0].listingIds.length,
              )}) selected listing${ternaryOperation(
                listingGroups[0].listingIds.length === 1,
                '',
                's',
              )}`,
            )}.`,
            `You are creating new reports from ${listingGroups.length} selected collections.`,
          )}>
          <div className={cn(['flex flex-col items-start w-full mt-6 sm:mt-4'])}>
            <div>
              <RadioButton
                classNames={cn(['mt-2'])}
                checked={selectedOption === ExportTypes.AVAILABILITY_REPORT}
                key={'reportBtns'}
                id={'reportRadioBtn'}
                disabled={maxReportListingsReached}
                label={'Create Customized Availability Report'}
                labelextended={isSmallScreen ? '' : `(${REPORT_MAX_LISTINGS} Listings Max)`}
                labelextendedclassnames={maxReportListingsReached ? 'text-freight-200' : ''}
                onClick={() => setSelectedOption(ExportTypes.AVAILABILITY_REPORT)}
                value={'ar'}
              />
              {isSmallScreen && (
                <Typography
                  variant="body-1"
                  className={cn([
                    'ml-[1.8rem] text-left',
                    maxReportListingsReached && 'text-freight-200',
                  ])}>
                  {`(${REPORT_MAX_LISTINGS} Listings Max)`}
                </Typography>
              )}
              <Typography variant="body-5" className="ml-[1.8rem] text-left text-cement-500">
                Report generated and sent via email
              </Typography>
            </div>
            {isAuthenticated && (
              <div>
                <RadioButton
                  classNames={cn(['mt-2'])}
                  checked={selectedOption === ExportTypes.VACANCY_REPORT}
                  key={'reportBtns'}
                  id={'vacancyReportRadioBtn'}
                  disabled={maxVacancyReportListingsReached}
                  label={'Download National Availability Report'}
                  labelextended={
                    isSmallScreen ? '' : `(${VACANCY_REPORT_MAX_LISTINGS} Listings Max)`
                  }
                  labelextendedclassnames={
                    maxVacancyReportListingsReached ? 'text-freight-200' : ''
                  }
                  onClick={() => setSelectedOption(ExportTypes.VACANCY_REPORT)}
                  value={'vr'}
                />
                {isSmallScreen && (
                  <Typography
                    variant="body-1"
                    className={cn([
                      'ml-[1.8rem] text-left',
                      maxVacancyReportListingsReached && 'text-freight-200',
                    ])}>
                    {`(${VACANCY_REPORT_MAX_LISTINGS} Listings Max)`}
                  </Typography>
                )}
                <Typography variant="body-5" className="ml-[1.8rem] text-left text-cement-500">
                  Direct download
                </Typography>
              </div>
            )}
            <div>
              <RadioButton
                classNames={cn(['mt-2'])}
                checked={selectedOption === ExportTypes.EXCEL_EXPORT}
                key={'reportBtns'}
                id={'excelReportRadioBtn'}
                label={'Export As Excel File'}
                onClick={() => setSelectedOption(ExportTypes.EXCEL_EXPORT)}
                value={'ex'}
              />
              <Typography variant="body-5" className="ml-[1.8rem] text-left text-cement-500">
                Direct download
              </Typography>
            </div>
          </div>
          {selectedOption === ExportTypes.AVAILABILITY_REPORT && (
            <>
              <div className="flex flex-col items-start w-full mt-10 mb-4 sm:mt-4 sm:mb-2">
                <Typography className="mb-4" variant="body-4">
                  Enter Report Name
                </Typography>
                <TextInput
                  classNames="h-[4.75rem] mb-[0.125rem] w-full"
                  error={!!reportNameError}
                  errorMessage={reportNameError}
                  onChange={handleReportNameOnChange}
                  value={reportName}
                  maxLength={MAX_CHARACTERS_ALLOWED}
                  required={true}
                  maxCharactersRemaining={maxCharactersRemaining}
                  showRemainingCharactersText={true}
                />
              </div>

              <div
                className={cn([
                  'flex flex-col items-start w-full',
                  showReportNameInput ? '' : 'mt-6 sm:mt-4',
                ])}>
                <Typography className="mb-4 mt-4" variant="body-4">
                  Recipients&apos; Emails
                </Typography>
                {isLargeScreen && emails.length > 0 && (
                  <Tooltip id="emails-info" className="z-10">
                    <Typography
                      variant="body-4"
                      className="sm:max-w-[16rem] md:max-w-[22rem] sm:max-h-[15rem] md:max-h-[25rem]">
                      {emails.join(', ')}
                    </Typography>
                  </Tooltip>
                )}
                <div
                  className="multiple-email"
                  data-tooltip-id="emails-info"
                  data-tooltip-place="top">
                  <ReactMultiEmail
                    placeholder="Recipients' Emails"
                    allowDuplicate={false}
                    emails={emails}
                    style={{
                      width: '100%',
                      maxWidth: isMediumScreen ? '445px' : '100%',
                      backgroundColor: '#eee',
                      borderColor: '#eee',
                      height: 'auto',
                      maxHeight: isSmallScreen ? 110 : 200,
                      overflowY: 'auto',
                    }}
                    className="mb-[0.125rem] w-full text-input bg-slate-50 h-auto"
                    onChange={onChangeEmails}
                    getLabel={getLabelInput}
                  />
                </div>
              </div>
            </>
          )}

          {showCreateCollectionCheckbox && (
            <Checkbox
              checkedState={createCollection}
              className="w-full sm:mt-3 md:mt-6"
              labelClassNames="text-left"
              disableIndeterminateOption
              disabled={fromCollections}
              onClick={(checkedState) => setCreateCollection(checkedState)}
              labelIcon={IconName.ADD_FOLDER}
              label="I would also like to create a collection with these listing(s)."
            />
          )}
        </ConfirmationModal>
      )}
    </>
  );
};

export default CreateReportModal;
