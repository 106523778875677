import { IconName } from '@components/Icon';

export enum TemplateAttributeKey {
  ClearHeight = 'CLEAR_HEIGHT',
  DateAvailable = 'CONTACT_US_OR_DATE_AVAILABLE',
  DigitalAssets = 'DIGITAL_ASSETS',
  DockDoors = 'LOADING_DOCKS',
  DriveIns = 'DRIVE_IN_DOORS',
  FullAddress = 'FULL_ADDRESS',
  Market = 'MARKET',
  Name = 'NAME',
  RailServed = 'RAIL_SERVED',
  SquareFootage = 'BUILDING_SQUARE_FOOTAGE',
  SitePlanUrl = 'MASTER_SITE_PLAN_URL',
  Submarket = 'SUBMARKET',
  TruckCourtDepth = 'TRUCK_COURT_DEPTH',
  TruckParking = 'TRUCK_PARKING_AVAILABLE',
  BuildingStatus = 'BUILDING_STATUS',
  PropertyType = 'PROPERTY_TYPE',
  propertyDescription = 'DESCRIPTION',
  ModifiedAt = 'MODIFIED_AT',
  PropertyId = 'PROPERTY_ID',
  UnitSquareFootage = 'UNIT_SQUARE_FOOTAGE',
  IsVisible = 'IS_VISIBLE',
  MarketDescription = 'MARKET_DESCRIPTION',
  BrokerFlyer = 'BROKER_FLYER',
}

export enum TemplateTypes {
  DetailedListing = 'LISTING_DETAILS',
  PropertySpecifications = 'LISTING_DETAILS__PROPERTY_SPECIFICATIONS',
  SitePlan = 'LISTING_DETAILS__SITE_PLAN',
  UnitSpecifications = 'LISTING_DETAILS__UNIT_SPECIFICATIONS',
  SquareFootageRange = 'LISTING_DETAILS__UNIT_SQUARE_FOOTAGE_RANGE',
  PropertyDescription = 'LISTING_DETAILS__PROPERTY_DESCRIPTION',
  ListingPopUp = 'MAP__LISTING_POPUP',
  ListingDetailsMap = 'LISTING_DETAILS__MAP',
  ContactInformation = 'LISTING_DETAILS__CONTACT_INFORMATION',
}

export type TemplateValue = {
  listingId: string;
  templateType: string;
  metadata: Record<string, any>;
  attributes: TemplateAttributeValue[];
  children: TemplateValue[];
  propertyType: string;
  seoJsonContext: string;
};

export type TemplateAttributeValue = {
  key: string;
  icon?: IconName;
  label?: string;
  value: string;
  rawValue: any;
};
