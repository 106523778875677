import Thumbnail from '@components/Thumbnail/Thumbnail';
import { Typography } from '@components/Typography';

export interface MapPinPopupContentProps {
  thumbnailUrl?: string;
  name?: string;
  fullAddress?: string;
  size?: string;
  unitName: string | undefined;
}

export const MapPinPopupContent: React.FC<MapPinPopupContentProps> = ({
  thumbnailUrl,
  name,
  fullAddress,
  size,
  unitName,
}) => (
  <>
    <Thumbnail imgClassNames="w-full h-[9.375rem]" propertyImageUrl={thumbnailUrl} />
    <div className="flex flex-col px-4 pt-2 pb-4 space-y-2">
      <Typography variant="subtitle-2">{name ?? 'Name'}</Typography>
      <Typography className="text-cement-500" variant="body-4">
        {fullAddress ?? 'Full Address'}
      </Typography>
      <Typography variant="body-4">{`${size ?? 'Size'}`}</Typography>
      {unitName && <Typography variant="body-4">{`${unitName ?? 'Unit Name'}`}</Typography>}
    </div>
  </>
);

export default MapPinPopupContent;
