import React, { useState } from 'react';

import { Typography } from '@components/Typography';

interface SitePlanImageProps {
  sitePlanUrl: any;
}

export const SitePlanImage: React.FC<SitePlanImageProps> = ({
  sitePlanUrl,
}: SitePlanImageProps) => {
  const [didError, setDidError] = useState(false);

  return !didError ? (
    <div className="flex w-full">
      <div className="gap-6 flexflex-col">
        <Typography className="!text-2xl !leading-[30px] !font-medium" variant="h5">
          Site Plan
        </Typography>

        <img
          className="max-w-full"
          src={sitePlanUrl}
          alt="The conceptual site plan for the listing"
          onError={() => setDidError(true)}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SitePlanImage;
